import { Outlet, NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import GroupIcon from '@mui/icons-material/Group'
import Divider from '@mui/material/Divider'
import LogoutButton from '../components/LogoutButton'

const drawerWidth = 258

const Wrapper = styled(Box)(() => ({
  display: 'flex'
}))

export default function SystemAdminLayout() {
  return (
    <Wrapper>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0
        }}
      >
        <Paper>
          <Box
            component={NavLink}
            to="/"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '64px',
              padding: '8px 24px',
              color: 'text.primary',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontFamily: 'Noto Sans JP'
            }}
          >
            Visnu 組織管理
          </Box>
          <Box sx={{ overflow: 'auto', width: drawerWidth, pt: 1 }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="組織管理" />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <Box sx={{ p: 2 }}>
              <LogoutButton />
            </Box>
          </Box>
        </Paper>
      </Drawer>
      <Box
        component="main"
        sx={{
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Container maxWidth="lg">
          <Outlet />
        </Container>
      </Box>
    </Wrapper>
  )
}
