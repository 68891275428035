import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useOrganizations } from '../hooks/useOrganizations'

const Top: React.FC = () => {
  const { organizations } = useOrganizations()

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" component="h2">
        組織管理
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>管理者</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.ids.map((id) => {
              const organization = organizations.organizations[id]
              return (
                <TableRow key={organization._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{organization._id}</TableCell>
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>
                    <List>
                      {organization.owners.map((owner) => {
                        return (
                          <ListItem key={owner._id}>
                            <ListItemText>{owner.email}</ListItemText>
                          </ListItem>
                        )
                      })}
                    </List>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default Top
