import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@mui/material/Button'
import Loading from './components/Loading'
import Layout from './pages/layout'
import Organization from './pages/organization'

export default function App() {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Organization />} />
        </Route>
        <Route path="callback/" element={<Callback />} />
      </Routes>
    </Router>
  )
}

function Login() {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
      ログイン
    </Button>
  )
}

function Callback() {
  return <Navigate to="/" />
}
