import { useAuth0 } from '@auth0/auth0-react'
import Button, { ButtonProps } from '@mui/material/Button'

export default function Logout(props: ButtonProps) {
  const { logout } = useAuth0()

  const onClick = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }

  return (
    <Button variant="outlined" onClick={onClick} {...props}>
      ログアウト
    </Button>
  )
}
