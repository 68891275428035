import { getAPIAccessToken as getAPIAccessTokenBase } from 'client-shared/lib/auth0.js'
import { auth0 } from '../constants'

type ArgsType = Parameters<typeof getAPIAccessTokenBase>

export const getAPIAccessToken = async (getAccessTokenSilently: ArgsType[0], getAccessTokenWithPopup: ArgsType[1]) => {
  return await getAPIAccessTokenBase(getAccessTokenSilently, getAccessTokenWithPopup, {
    authorizationParams: {
      audience: auth0.authorizationParams.audience
    }
  })
}
