import { defineApis, define } from '@tiny-type-api/universal'

type AuthHeader = { Authorization: `Bearer ${string}` }

export const { apis } = defineApis({
  '/api/@me': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{ email: string; roles: string[] }>()
        },
        404: {
          body: define<''>()
        }
      }
    }
  },
  '/api/@me/profile': {
    POST: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/@me/actcastDeviceGroups': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            actcastDeviceGroups: {
              _id: string
              name: string
              organizationId: string
              actcastDevices: string[]
            }[]
          }>()
        }
      }
    }
  },
  '/api/@me/organizations': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            organizations: {
              _id: string
              name: string
              owners: {
                _id: string
                email: string
              }[]
            }[]
          }>()
        }
      }
    }
  },
  '/api/actcastDeviceGroups/:groupId/devices': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            actcastDevices: {
              _id: string
              actcastId: string
              hostname: string
              name: string
              mac_addr: string
              tags: string[]
              foundness: string
              status: {
                uptime: number
                memory: number
                disk: number
                memory_usage: number
                disk_usage: number
                throttled: number
                cpu_temperature: number
              } | null
            }[]
          }>()
        }
      }
    }
  },
  '/api/photo/:deviceId/latest': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            url: string
            taken_at: string // TODO: yyyy-mm-ddThh:MM:ssZ 形式
          } | null>()
        }
      }
    }
  },
  '/api/photo/:deviceId/request': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/photo/:deviceId/list': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            files: {
              url: string
              taken_at: string // TODO: yyyy-mm-ddThh:MM:ssZ 形式
            }[]
          }>()
        }
      }
    }
  },
  '/api/deliveryDevice/log': {
    POST: {
      request: {
        headers: define<{
          Authorization: string
        }>(),
        body: define<{
          photo: string
          latitude: number
          longitude: number
          altitude: number
          timestamp: string
        }>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  }
})

export type API = typeof apis
